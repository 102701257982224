import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import AuthService from "@/services/AuthService";

jest.mock("@/services/MyRentalsConfig");

describe("MYHExternalRoutes", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });
  it("login", () => {
    expect(MYHExternalRoutes.login()).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: { cannotBeImpersonated: true },
      path: "/login",
    });
  });
  it("help", () => {
    expect(MYHExternalRoutes.help()).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: undefined,
      path: "/help",
    });
  });
  it("visitas", () => {
    expect(MYHExternalRoutes.visits()).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: undefined,
      path: "/dashboard/visitas",
    });
  });
  it("dashboardProperty with dealId", () => {
    expect(MYHExternalRoutes.dashboardProperty("uuid", 1)).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: undefined,
      path: "/dashboard/inmuebles/uuid/1",
    });
  });

  it("dashboardProperty without dealId", () => {
    expect(MYHExternalRoutes.dashboardProperty("uuid")).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: undefined,
      path: "/dashboard/inmuebles/uuid",
    });
  });

  it("valuationTool", () => {
    expect(MYHExternalRoutes.valuationTool()).toEqual({
      domain: "http://localhost:30003",
      isExternal: true,
      options: undefined,
      path: "/tools/valuation",
    });
  });

  it("simulatorTool", () => {
    expect(MYHExternalRoutes.simulatorTool()).toEqual({
      domain: "http://localhost:30004",
      isExternal: true,
      options: undefined,
      path: "/tools/mortgage-simulator",
    });
  });

  it("portal", () => {
    expect(MYHExternalRoutes.portal()).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: {
        cannotBeImpersonated: true,
        shouldOpenNewWindow: true,
      },
      path: "/alquiler-inmuebles",
    });
  });
  it("propertyPreview", () => {
    expect(MYHExternalRoutes.propertyPreview("uuid")).toEqual({
      domain: "http://localhost:3000",
      isExternal: true,
      options: {
        cannotBeImpersonated: true,
        shouldOpenNewWindow: true,
      },
      path: "/preview/property?uuid=uuid",
    });
  });
  describe("dashboardPropertyCreation", () => {
    it("should create correctly the url if hasAppointmentManager is false", () => {
      const mockedHasAppointmentManager = false;
      expect(
        MYHExternalRoutes.dashboardPropertyCreation(
          "uuid",
          mockedHasAppointmentManager
        )
      ).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: undefined,
        path: "/dashboard/inmuebles/creation/uuid?has_am=false",
      });
    });
    it("should create correctly the url if hasAppointmentManager is true", () => {
      const mockedHasAppointmentManager = true;
      expect(
        MYHExternalRoutes.dashboardPropertyCreation(
          "uuid",
          mockedHasAppointmentManager
        )
      ).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: undefined,
        path: "/dashboard/inmuebles/creation/uuid?has_am=true",
      });
    });
  });
  describe("dashboardPropertyPublished", () => {
    it("should create correctly the url if hasAppointmentManager is true", () => {
      const mockedHasAppointmentManager = true;
      expect(
        MYHExternalRoutes.dashboardPropertyPublished(
          "uuid",
          mockedHasAppointmentManager
        )
      ).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: undefined,
        path: "/dashboard/inmuebles/published/uuid?has_am=true",
      });
    });
    it("should create correctly the url if hasAppointmentManager is false", () => {
      const mockedHasAppointmentManager = false;
      expect(
        MYHExternalRoutes.dashboardPropertyPublished(
          "uuid",
          mockedHasAppointmentManager
        )
      ).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: undefined,
        path: "/dashboard/inmuebles/published/uuid?has_am=false",
      });
    });
  });

  describe("Impersonate", () => {
    it("login", () => {
      spyOn(AuthService, "isImpersonate").and.returnValue(true);
      spyOn(AuthService, "impersonateUserId").and.returnValue(2);

      expect(MYHExternalRoutes.login()).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: {
          cannotBeImpersonated: true,
        },
        path: "/login",
      });
    });
    it("visitas", () => {
      spyOn(AuthService, "isImpersonate").and.returnValue(true);
      spyOn(AuthService, "impersonateUserId").and.returnValue(2);

      expect(MYHExternalRoutes.visits()).toEqual({
        domain: "http://localhost:3000",
        isExternal: true,
        options: undefined,
        path: "/dashboard/visitas",
      });
    });
  });
});
